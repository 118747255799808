import React, { useState } from 'react'
import './glitch.scss'
import './emoji.scss'
import './spin.scss'

const classNames = [
  'money-mouth',
  'money-bag',
  'money-wings',
  'money-banknote',
  'money-card',
  'money-bank',
  'money-coin',
  'money-yen',
  'money-euro',
  'money-pound',
]

const App = () => {
  const [index, setIndex] = useState(0)

  return (
    <div
      className={classNames[index]}
      onClick={() => setIndex(Math.floor(Math.random() * classNames.length))}
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <span
        className="spin-time"
        style={{
          fontSize: '30em',
          opacity: '.09',
          rotate: '90',
          position: 'absolute',
        }}
      >
        🤑
      </span>
      <div className="glitch" data-text="michrob.com">
        <span className="glitch__color glitch__color--red">michrob.com</span>
        <span className="glitch__color glitch__color--blue">michrob.com</span>
        <span className="glitch__color glitch__color--green">michrob.com</span>
        <span className="glitch__main">michrob.com</span>
        <span className="glitch__line glitch__line--first"></span>
        <span className="glitch__line glitch__line--second"></span>
      </div>
    </div>
  )
}

export default App
